import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta';
import slugify from 'slugify';
import Pusher from 'pusher-js';


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(VueMeta);
Vue.filter('slugifyName',function (value: string) {
  if (value !== null && value !== undefined){
    value = value.replace('&', 'e')
    return slugify(value,{remove: /[*+~.()'/"!?:@]/g}).toLowerCase();
  }
  return '';
})
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
