import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import axios from 'axios'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/control/login',
    component: () => import('../views/Admin/Login.vue')
  },
  {
    path: '/',    
    name: 'wait',
    meta: { requiresAuthUser: true },
    component: () => import('../views/Live/Live.vue')
  },
  {
    path: '/control/admin',
    meta: { requiresAuth: true },
    name: 'home',
    component: () => import('../views/Admin/Home.vue')
  },
  {
    path: '/control/admin/lives/:code',
    meta: { requiresAuth: true },
    name: 'lives',
    component: () => import('../views/Admin/Lives.vue')
  },  
  {
    path: '/control/admin/users/:code',
    meta: { requiresAuth: true },
    name: 'users',
    component: () => import('../views/Admin/User.vue')
  },  
  {
    path: '/apresentador/:client/:live',    
    name: 'apresentador-interna',
    meta: { requiresAuthApresenta: true },
    component: () => import('../views/Live/Apresentador.vue')
  },
  {
    path: '/login/:client/:live',    
    name: 'login-interna',
    component: () => import('../views/Live/Login.vue')
  },
  {
    path: '/naturaeco/to',    
    name: 'natura-to',
    component: () => import('../views/Live/NaturaTo.vue')
  },    
  {
    path: '/:client/:live',
    meta: { requiresAuthUser: true },
    name: 'lives-interna',
    component: () => import('../views/Live/Live.vue')
  },  
  {
    path: '/emoji/:client/:live',    
    name: 'emoji-interna',
    component: () => import('../views/Live/Emoji.vue')
  },  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const isAuthenticated = () => {
  return localStorage.getItem('userToken');
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {      
    console.log(isAuthenticated());
    if (isAuthenticated() == null) {
      next({
        path: '/control/login',
      });
      return;
    }
  }
  if (to.matched.some((record) => record.meta.requiresAuthApresenta)) {
    const obj = await axios.get(`${process.env.VUE_APP_API}live-read??client=auren&slug=aurenday2024`)
    console.log(obj)
    if (obj !== undefined && obj.data !== undefined){
      if (localStorage.getItem(`email-aurenday2024`) !== null){
        if (obj.data.question !== undefined && obj.data.question !== ''){
          const data = obj.data.question.split(';')
          for(const item of data){            
            if (item == localStorage.getItem(`email-aurenday2024`)){
              next();
              return
            }
          }
        }
      }
    }
    next({path: `login/auren/aurenday2024`});
    return;
  }
  if (to.matched.some((record) => record.meta.requiresAuthUser)) {
    const obj = await axios.get(`${process.env.VUE_APP_API}live-read?client=auren&slug=aurenday2024`)
    if (localStorage.getItem(`${obj.data._id}-user-aurenday2024`) == null){
      next({path: `login/auren/aurenday2024`});
      return;
    }
  }
  next();
});

export default router
